import React from "react";
import PropTypes from "prop-types";
import "../../../Components/Styless.css";
import Confetti from "react-confetti";

const ModalComponent = ({ isVisible, onClose, offerData }) => {
  if (!isVisible) return null;


  return (
    
    <div className="modal-overlay">
      <Confetti 
      tweenDuration={1000}
      numberOfPieces={300}
      />
      <div className="modal-card coupon-card position-relative p-0 m-0" >
        <div className="text-center" >
          <img src={offerData.image_url} alt={offerData.title} className="offer-image" style={{width : "100%", height: "60%", display: "block", margin: "0 auto" , borderRadius: "10px"}}/>
          <h3 className="text-center mt-3 fw-bold" style={{ color: "#3789FF"}}>
            🎉 {offerData.title || "Special Offer"} 🎉
          </h3>
          <p className="text-center text-dark mt-3">
            {offerData.description || "Details not available."}
          </p>
          <button className="action-btn mt-3 bg-danger text-white border-0 p-2 px-5 w-50" onClick={onClose} style={{ cursor: "pointer", borderRadius: "5px" }}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

ModalComponent.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  offerData: PropTypes.object, 
};

export default ModalComponent;
