import React, { useState, useEffect } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { BASE_URL } from "../../Services";
import { useUser } from "../../Services/UserContext";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";
import htmlTruncate from "html-truncate";

function BlogList() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const { userData } = useUser();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/blogs`, {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });

        if (
          response.data.status === 200 &&
          Array.isArray(response.data.blogs)
        ) {
          setBlogs(response.data.blogs);
        } else {
          console.error("Unexpected response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching blogs:", error);
        toast.error(
          `Error fetching blogs: ${
            error.response?.data?.message || error.message
          }`
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  const handleEditClick = (id) => {
    navigate(`/dashboard/blogs/${id}/edit`);
  };

  const handleViewClick = (id) => {
    navigate(`/dashboard/blogs/${id}`);
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`${BASE_URL}/blogs/${id}`, {
        headers: {
          Authorization: `Bearer ${userData?.token}`,
        },
      });
      setBlogs(blogs.filter((blog) => blog.id !== id));
      toast.success("Blog deleted successfully!");
    } catch (error) {
      console.error("Error deleting blog:", error);
      toast.error(
        `Error deleting blog: ${error.response?.data?.message || error.message}`
      );
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleAddClick = () => {
    navigate("/dashboard/blogs/add");
  };

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  const filteredBlogs = blogs.filter(
    (blog) =>
      blog.heading.toLowerCase().includes(searchTerm.toLowerCase()) ||
      blog.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          <div className="dashboard-content p-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 bg-body rounded">
                Blogs List
              </h1>
              <button
                className="btn btn-primary shadow-sm"
                onClick={handleAddClick}
              >
                <i className="bi bi-plus-circle me-2"></i>Add New Blog
              </button>
            </div>

            <input
              type="text"
              placeholder="Search Blogs..."
              className="form-control mb-4"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            {loading ? (
              <div
                className="d-flex justify-content-center"
                style={{ height: "60vh" }}
              >
                <Loader />
              </div>
            ) : (
              <div className="table-responsive">
                <table
                  className="table table-hover table-striped table-bordered shadow-sm rounded"
                  style={{ fontSize: "14px" }}
                >
                  <thead className="table-dark">
                    <tr>
                      <th>Slug</th>
                      <th>Heading</th>
                      <th>Description</th>
                      <th className="text-center">Image</th>
                      <th className="col-2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredBlogs.length ? (
                      filteredBlogs.map((blog) => (
                        <tr key={blog.id}>
                          <td
                            className="text-truncate align-middle"
                            style={{ maxWidth: "100px" }}
                          >
                            {blog.slug}
                          </td>
                          <td
                            className="text-truncate align-middle"
                            style={{ maxWidth: "150px" }}
                          >
                            {blog.heading}
                          </td>
                          <td
                            className="text-truncate text-dark align-middle"
                            style={{ maxWidth: "300px" }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: htmlTruncate(blog.description, 20),
                              }}
                            />
                          </td>
                          <td className="text-center align-middle p-2">
                            {blog.blog_image && (
                              <img
                                style={{
                                  width: "90%",
                                  height: "110px",
                                  borderRadius: "10px",
                                }}
                                src={blog.blog_image}
                                alt={blog.heading}
                              />
                            )}
                          </td>
                          <td className="text-center align-middle">
                            <div className="d-flex justify-content-center gap-2">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => handleEditClick(blog.slug)}
                              >
                                <i className="bi bi-pencil"></i>
                              </button>
                              <button
                                className="btn btn-danger btn-sm"
                                onClick={() => {
                                  setItemToDelete(blog.id);
                                  setShowDeleteModal(true);
                                }}
                              >
                                <i className="bi bi-trash"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center align-middle">
                          No blogs available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      {showDeleteModal && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Confirm Deletion</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowDeleteModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>Are you sure you want to delete this blog?</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => setShowDeleteModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleDeleteClick(itemToDelete)}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer />
    </div>
  );
}

export default BlogList;
