import React, { useState, useEffect } from "react";
import { BASE_URL } from "../../Services";
import axios from "axios";
import Loader from "../../UserSide/Components/LoaderComponent";
import Sidebar from "../sidebar/Sidebar";

import dayjs from "dayjs";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Avatar,
} from "@mui/material";
import { People, Paid, AttachMoneyOutlined } from "@mui/icons-material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import DateRangePickerComponent from "./Components/DatePicker";
import { Link } from "react-router-dom";
import useMonthlyStats from "./Components/useMonthlyStats";

const UserStats = () => {
  const {
    currentMonthData,
    previousMonthData,
    users,
    participants,
    calculatePaidParticipants,
    calculateTotalSales,
    products,
    loading,
  } = useMonthlyStats();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [filteredTotalUsers, setFilteredTotalUsers] = useState(0);
  const [paidParticipants, setPaidParticipants] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [filteredTotalAmount, setFilteredTotalAmount] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);
  const [filteredTotalQuantity, setFilteredTotalQuantity] = useState(0);
  const [bikecount, setBikeCount] = useState(0);
  const [iphonecount, setIphoneCount] = useState(0);
  const [airpodscount, setairpodsCount] = useState(0);

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };
  useEffect(() => {
    if (participants.length > 0) {
      setPaidParticipants(calculatePaidParticipants(participants));
      setTotalSales(calculateTotalSales(participants));
    }
  }, [participants, calculatePaidParticipants, calculateTotalSales]);

  useEffect(() => {
    if (products.length > 0 && products[0].status === 200) {
      products[0].icon = TwoWheelerIcon;
    }
  }, [products]);

  const filterStatsByDate = () => {
    const [startDate, endDate] = calculateDateRange(dateRange);
    const filteredParticipants = participants.filter((participant) => {
      const createdAt = new Date(participant.created_at);
      return createdAt >= startDate && createdAt <= endDate;
    });
    const filteredInvoices = filteredParticipants
      .map((participant) => participant.processed_invoice)
      .filter((invoice) => {
        const invoiceCreatedAt = new Date(invoice.created_at);
        return invoiceCreatedAt >= startDate && invoiceCreatedAt <= endDate;
      });
    const filteredTotalQuantity = filteredInvoices.reduce((sum, invoice) => {
      return sum + invoice.total_quantity;
    }, 0);

    setFilteredTotalAmount(calculateTotalSales(filteredParticipants));
    setFilteredTotalQuantity(filteredTotalQuantity);

    const { iphoneCount, bikeCount, airpodsCount } =
      countProducts(filteredParticipants);
    setBikeCount(bikeCount);
    setIphoneCount(iphoneCount);
    setairpodsCount(airpodsCount);

    const filteredUsers = users.filter((user) => {
      const createdAt = new Date(user.created_at);
      return createdAt >= startDate && createdAt <= endDate;
    });

    setFilteredTotalUsers(filteredUsers.length);
  };

  const calculateDateRange = ([start, end]) => {
    const startDate = start
      ? new Date(start)
      : dayjs().subtract(1, "day").toDate();
    const endDate = end ? new Date(end) : dayjs().endOf("day").toDate();
    const formattedStartDate = new Date(
      `${startDate.toISOString().split("T")[0]}T00:00:00Z`
    );
    const formattedEndDate = new Date(
      `${endDate.toISOString().split("T")[0]}T23:59:59Z`
    );

    return [formattedStartDate, formattedEndDate];
  };
  const countProducts = (participants) => {
    let iphoneCount = 0;
    let bikeCount = 0;
    let airpodsCount = 0;

    participants.forEach((participant) => {
      if (
        participant.processed_invoice &&
        Array.isArray(participant.processed_invoice.products)
      ) {
        participant.processed_invoice.products.forEach((product) => {
          const productName = product.name?.toLowerCase();
          if (productName === "ev-bike") bikeCount++;
          if (productName === "digital watch") iphoneCount++;
          if (productName === "air pods") airpodsCount++;
        });
      }
    });

    return { iphoneCount, bikeCount, airpodsCount };
  };

  useEffect(() => {
    filterStatsByDate();
  }, [dateRange, participants]);
  // useEffect(() => {
  //   fetchUserStats();
  //   const interval = setInterval(fetchUserStats, 60000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          <div className="dashboard-content p-4">
            <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 bg-light rounded text-left">
              Current Stats
            </h1>
            {loading ? (
              <div
                className="d-flex justify-content-center align-items-center dashboard-loader"
                style={{ height: "60vh" }}
              >
                <Loader />
              </div>
            ) : (
              <>
                {currentMonthData && (
                  <Box sx={{ mt: 3 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#3f51b5", mr: 2 }}>
                                <People />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Users
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {currentMonthData?.total_users}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard/participants"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#f57c00", mr: 2 }}>
                                <Paid />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Participants
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {currentMonthData?.total_participants}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard/invoices"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#009688", mr: 2 }}>
                                <AttachMoneyOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Tickets
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {currentMonthData?.total_quantity}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard/participants"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#009688", mr: 2 }}>
                                <AttachMoneyOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Amount
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  PKR. {currentMonthData?.total_amount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 mt-4 bg-light rounded text-left">
                  Previous Month
                </h1>
                {previousMonthData && (
                  <Box sx={{ mt: 3 }}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#3f51b5", mr: 2 }}>
                                <People />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Users
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {previousMonthData?.total_users}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard/participants"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#f57c00", mr: 2 }}>
                                <Paid />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Participants
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {previousMonthData?.total_participants}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard/invoices"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#009688", mr: 2 }}>
                                <AttachMoneyOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Tickets
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {previousMonthData?.total_quantity}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={"/dashboard/participants"}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#009688", mr: 2 }}>
                                <AttachMoneyOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Total Amount
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  PKR. {previousMonthData?.total_amount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 mt-4 bg-light rounded text-left">
                  Total Stats
                </h1>
                <Box sx={{ mt: 3 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Link to={"/dashboard"}>
                        <Card
                          sx={{
                            borderRadius: "16px",
                            boxShadow: 3,
                            transition:
                              "transform 0.3s ease, box-shadow 0.3s ease",
                            "&:hover": {
                              transform: "scale(1.05)",
                              boxShadow: 6,
                            },
                          }}
                        >
                          <CardContent
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar sx={{ bgcolor: "#3f51b5", mr: 2, mb: 6 }}>
                              <People />
                            </Avatar>
                            <Box>
                              <Typography
                                variant="h5"
                                sx={{ display: "block", lineHeight: 2 }}
                              >
                                Total Users
                              </Typography>
                              <Typography
                                variant="h5"
                                fontWeight="bold"
                                sx={{ mt: 0.5 }}
                              >
                                {users.length}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Link to={"/dashboard/participants"}>
                        <Card
                          sx={{
                            borderRadius: "16px",
                            boxShadow: 3,
                            transition:
                              "transform 0.3s ease, box-shadow 0.3s ease",
                            "&:hover": {
                              transform: "scale(1.05)",
                              boxShadow: 6,
                            },
                          }}
                        >
                          <CardContent
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar sx={{ bgcolor: "#f57c00", mr: 2, mb: 6 }}>
                              <Paid />
                            </Avatar>
                            <Box>
                              <Typography
                                variant="h5"
                                sx={{ display: "block", lineHeight: 2 }}
                              >
                                Paid Participants
                              </Typography>
                              <Typography
                                variant="h5"
                                fontWeight="bold"
                                sx={{ mt: 0.5 }}
                              >
                                {paidParticipants}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Link to={"/dashboard/participants"}>
                        <Card
                          sx={{
                            borderRadius: "16px",
                            boxShadow: 3,
                            transition:
                              "transform 0.3s ease, box-shadow 0.3s ease",
                            "&:hover": {
                              transform: "scale(1.05)",
                              boxShadow: 6,
                            },
                          }}
                        >
                          <CardContent
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Avatar sx={{ bgcolor: "#009688", mr: 2, mb: 6 }}>
                              <AttachMoneyOutlined />
                            </Avatar>
                            <Box>
                              <Typography
                                variant="h5"
                                sx={{ display: "block", lineHeight: 2 }}
                              >
                                Total Sales
                              </Typography>
                              <Typography
                                variant="h5"
                                fontWeight="bold"
                                sx={{ mt: 0.5 }}
                              >
                                PKR. {totalSales.toFixed(2)}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ mt: 3 }}>
                  <Grid container spacing={3}>
                    {products.map((product) => (
                      <Grid item xs={12} sm={6} md={4} key={product.id}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              {product.id == "38" ? (
                                <Avatar
                                  sx={{ bgcolor: "#FF5722", mr: 2, mb: 6 }}
                                >
                                  <TwoWheelerIcon />
                                </Avatar>
                              ) : product.id == "39" ? (
                                <Avatar
                                  sx={{ bgcolor: "#4CAF50", mr: 2, mb: 6 }}
                                >
                                  <PhoneAndroidIcon />
                                </Avatar>
                              ) : (
                                <Avatar
                                  sx={{ bgcolor: "#FFC107", mr: 2, mb: 6 }}
                                >
                                  <ReceiptIcon />
                                </Avatar>
                              )}

                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{
                                    display: "block",
                                    lineHeight: 2,
                                  }}
                                >
                                  {product.name}
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  Sold : {product.sold || 0}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    ))}
                  </Grid>
                  <Box sx={{ mt: 6 }}>
                    <div className="fs-3 fw-bold text-dark shadow-sm p-3 mb-4 bg-light rounded text-left d-flex justify-content-between align-items-center">
                      <h1 className="fs-3 fw-bold text-dark text-left mt-3">
                        Difference
                      </h1>
                      <div className="text-right">
                        <DateRangePickerComponent onDateChange={setDateRange} />
                      </div>
                    </div>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#3f51b5", mr: 2, mb: 6 }}>
                                <People />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Users:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {filteredTotalUsers}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/participants`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#009688", mr: 2, mb: 6 }}>
                                <AttachMoneyOutlined />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Sales:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  PKR. {filteredTotalAmount.toFixed(2)}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/invoices`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#009688", mr: 2, mb: 6 }}>
                                <ReceiptIcon />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Tickets:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {filteredTotalQuantity}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} sx={{ mt: 1 }}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#FF5722", mr: 2, mb: 6 }}>
                                <TwoWheelerIcon />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Bike Sold:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {bikecount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#4CAF50", mr: 2, mb: 6 }}>
                                <PhoneAndroidIcon />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Digital Watch:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {iphonecount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Link to={`/dashboard/products`}>
                          <Card
                            sx={{
                              borderRadius: "16px",
                              boxShadow: 3,
                              transition:
                                "transform 0.3s ease, box-shadow 0.3s ease",
                              "&:hover": {
                                transform: "scale(1.05)",
                                boxShadow: 6,
                              },
                            }}
                          >
                            <CardContent
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar sx={{ bgcolor: "#FFC107", mr: 2, mb: 6 }}>
                                <ReceiptIcon />
                              </Avatar>
                              <Box>
                                <Typography
                                  variant="h5"
                                  sx={{ display: "block", lineHeight: 2 }}
                                >
                                  Airpods Sold:
                                </Typography>
                                <Typography
                                  variant="h5"
                                  fontWeight="bold"
                                  sx={{ mt: 0.5 }}
                                >
                                  {airpodscount}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserStats;
