import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { BASE_URL } from "../../../Services";
import { useUser } from "../../../Services/UserContext.js";

import Header from "../../Components/HeaderComponent";
import Footer from "../../Components/FooterCompnent";
import Loader from "../../Components/LoaderComponent";
import FlipClock from "flipclock";
import "../../css/Styles.css";
import "flipclock/dist/flipclock.css";
import "react-toastify/dist/ReactToastify.css";
import TimerApp from "./components/TimerApp.js";

const GameScreen = () => {
    // console.log("kamehamehaaaa2")
    const { userData } = useUser();
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [selectedOption, setSelectedOption] = useState("");
    const [result, setResult] = useState("");
    const [activeOption, setActiveOption] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const clockRef = useRef(null);
    const hasFetchedQuestions = useRef(false);
    
    const fetchCartData = async () => {
        // console.log("kamehamehaaaa1")
        if (!userData) return;
        try {
            const cartResponse = await axios.get(`${BASE_URL}/cart`, {
                headers: { Authorization: `Bearer ${userData.token}` },
            });
            if (cartResponse.data?.status !== 200) {
                console.error("Failed to fetch cart data");
            }
} catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };
  
  const fetchQuestions = async () => {
    if (!userData) return;
    if (hasFetchedQuestions.current) return;
    try {
      const response = await axios.get(`${BASE_URL}/questions`, {
        headers: { Authorization: `Bearer ${userData?.token}` },
      });
      if (response.data.status === "Success") {
          selectRandomQuestion(response.data.data);
          hasFetchedQuestions.current = true;
        } else {
            console.error("Failed to fetch questions.");
        }
    } catch (error) {
        console.error("Error fetching questions:", error);
    }
};

useEffect(() => {
    // console.log("kamehamehaaaa3")
   
    fetchCartData();
    fetchQuestions();
    return () => {
        hasFetchedQuestions.current = false; 
    };
}, [userData]);

const selectRandomQuestion = (questionsList) => {
    // console.log("kamehamehaaaa5")
    if (questionsList.length > 0) {
        // console.log("kamehamehaaaa4")
        const randomIndex = Math.floor(Math.random() * questionsList.length);
        setCurrentQuestion(questionsList[randomIndex]);
    }
  };

  useEffect(() => {
    if (clockRef.current) {
        const clock = new FlipClock(clockRef.current, {
            clockFace: "HourlyCounter",
            autoStart: false,
            callbacks: {
                stop: () => window.location.href = `${BASE_URL}/questions`,
            },
        });
        clock.setTime(180);
      clock.setCountdown(true);
      clock.start();
    }
  }, [clockRef]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedOption) {
      toast.error("Please select an option");
      return;
    }

    const isCorrect = currentQuestion.options.some(
      (option) =>
        option.optionTitle === selectedOption && option.correctOption === "Y"
    );
    setResult(isCorrect ? "Correct!" : "Wrong answer!");

    try {
      await axios.post(
        `${BASE_URL}/cart/update-is-correct`,
        { is_correct: isCorrect ? "yes" : "no" },
        { headers: { Authorization: `Bearer ${userData?.token}` } }
      );
      const cartResponse = await axios.get(`${BASE_URL}/cart`, {
        headers: { Authorization: `Bearer ${userData.token}` },
      });

      await createInvoice(isCorrect);
    } catch (error) {
      console.error("Error processing the request:", error);
    }
  };

  const createInvoice = async (isCorrect) => {
    const formattedTime = getFormattedTime();
    let promoCode = localStorage.getItem("promoCode");

    try {
      const newObj = {
        is_correct: isCorrect ? "yes" : "no",
        answer_time: formattedTime,
        promo_codes: promoCode,
        platform: "web",
      };
      const response = await axios.post(
        `${BASE_URL}/cart/addInvoice`,
        newObj,
        { headers: { Authorization: `Bearer ${userData?.token}` } }
      );
      const invoiceId = response?.data?.invoice?.invoice_id;
      window.location.href = `https://www.dukan.pk/enaam?invoice_id=${invoiceId}`;
      localStorage.removeItem("promoCode");
    } catch (error) {
      console.error("Error submitting cart data:", error);
    }
  };

  const getFormattedTime = () => {
    const startTime = localStorage.getItem("timerStartTime");
    if (!startTime) return "0:00";

    const currentTime = Date.now();
    const elapsed = Math.floor((currentTime - startTime) / 1000);
    const remainingTime = Math.max(120 - elapsed, 0);
    const minutes = Math.floor(remainingTime / 60);
    const seconds = remainingTime % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleOptionClick = (optionValue) => {
    setSelectedOption(optionValue);
    setActiveOption(optionValue);
  };

  if (!currentQuestion) return <Loader />;

  return (
    <div className="App">
      <ToastContainer />
      <Header />
      <div className="container-fluid col-lg-10 pt-0 p-4">
        <div style={{ height: "10vh" }} />
        <div className="d-flex justify-content-end mr-3 timer">
          <TimerApp />
        </div>

        <div className="row">
          <div className="col-md-4 question-section">
            <div className="question " style={{ textAlign: "center" }}>
              <p
                style={{
                  width: "70%",
                  fontWeight: "bold",
                  margin: "0px !important",
                }}
              >
                {currentQuestion.title}
              </p>
            </div>
            <div className="question-wrapper">
              <p className="text-center">Click to select your answer</p>
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={handleSubmit}
              >
                CONFIRM
              </button>
            </div>
          </div>
          <div className="col-md-8">
            <div className="game-wrapper" style={{ padding: 20 }}>
              <div
                className="row justify-content-center"
                style={{ height: "100%" }}
              >
                {currentQuestion.options.map((option, index) => (
                  <div
                    className="col-sm-6 col-md-5 col-lg-5 mb-3"
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className={`glow option-block ${
                        selectedOption === option.optionTitle ? "selected" : ""
                      }`}
                      onClick={() => handleOptionClick(option.optionTitle)}
                      style={{
                        width: "100%",
                        padding: "10px",
                        borderRadius: "8px",
                        backgroundColor:
                          selectedOption === option.optionTitle
                            ? "#007bff"
                            : "#f8f9fa",
                        color:
                          selectedOption === option.optionTitle
                            ? "#fff"
                            : "#000",
                        cursor: "pointer",
                        textAlign: "center",
                        transition:
                          "background-color 0.3s ease, border-color 0.3s ease",
                      }}
                    >
                      <input
                        type="radio"
                        id={`option${index + 1}-${currentQuestion.id}`}
                        name={`question-${currentQuestion.id}`}
                        value={option.optionTitle}
                        checked={selectedOption === option.optionTitle}
                        onChange={() => setSelectedOption(option.optionTitle)}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor={`option${index + 1}-${currentQuestion.id}`}
                        style={{
                          width: "100%",
                          alignItems: "center",
                          cursor: "pointer",
                          fontWeight: "bold",
                          fontSize: "1rem",
                          padding: "10px",
                        }}
                      >
                        {index + 1 + ".   " + option.optionTitle}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {showErrorModal && (
        <div className="modal-overlay">
          <div className="Error-modal position-relative">
            <div className="text-center">
              <img
                src={require("../../images/enaam 4.png")}
                alt="Enaam Logo"
                className="logo mb-3 mt-2"
              />
              <h3 className="text-center text-white">
                Dear , {userData?.name || "User"}!
              </h3>
              <h4 className="text-center text-white mt-3">
                Opps, No Tickets Found! Please try again and Buy Tickets!
              </h4>

              <div className="coupon-row mt-4">
                <button
                  type="submit"
                  className="btn btn-dark text-white px-5"
                  onClick={() => setShowErrorModal(false)}
                  style={{ width: "100%" }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GameScreen;
