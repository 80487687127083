import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent';

function OfferList() {
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const { userData } = useUser();

    useEffect(() => {
        const fetchOffers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/offers`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                    },
                });
                if (Array.isArray(response.data)) {
                    setOffers(response.data);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            } catch (error) {
                console.error('Error fetching offers:', error);
                toast.error(`Error fetching offers: ${error.response?.data?.message || error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchOffers();
    }, [userData?.token]);

    const handleEditClick = (id) => {
        navigate(`/dashboard/offers/${id}/edit`);
    };

    const handleDeleteClick = async (id) => {
        try {
            await axios.delete(`${BASE_URL}/offers/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`,
                },
            });
            
            setOffers(offers.filter((offer) => offer.id !== id));
            toast.success('Offer deleted successfully!');
        } catch (error) {
            console.error('Error deleting offer:', error);
            toast.error(`Error deleting offer: ${error.response?.data?.message || error.message}`);
        } finally {
            setShowDeleteModal(false);
        }
    };

    const handleAddClick = () => {
        navigate('/dashboard/offers/add');
    };

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    const filteredOffers = offers.filter((offer) =>
        offer.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        offer.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                    <div className="dashboard-content p-4">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 bg-body rounded">Offers List</h1>
                            <button className="btn btn-primary shadow-sm" onClick={handleAddClick}>
                                <i className="bi bi-plus-circle me-2"></i>Add New Offer
                            </button>
                        </div>

                        <input
                            type="text"
                            placeholder="Search Offers..."
                            className="form-control mb-4"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />

                        {loading ? (
                            <div className="d-flex justify-content-center" style={{ height: '60vh' }}>
                                <Loader />
                            </div>
                        ) : (
                            <div className="table-responsive">
                                <table className="table table-hover table-striped table-bordered shadow-sm rounded" style={{ fontSize: '14px' }}>
                                    <thead className="table-dark">
                                        <tr>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th className="text-center">Image</th>
                                            <th>Status</th>
                                            <th className="col-md-1 text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredOffers.length ? (
                                            filteredOffers.map((offer) => (
                                                <tr key={offer.id}>
                                                    <td className="text-left align-middle">{offer.title}</td>
                                                    <td className="text-left align-middle">{offer.description.slice(0, 80)}</td>
                                                    <td className="text-center align-middle p-2">
                                                        {offer.image_url && (
                                                            <img style={{ width: '100%', height: '100px', borderRadius: '10px' }}
                                                                src={offer.image_url}
                                                                alt={offer.title}
                                                            />
                                                        )}
                                                    </td>
                                                    <td className="text-center align-middle">{offer.status ? 'Active' : 'Inactive'}</td>
                                                    <td className="text-center align-middle">
                                                        <div className="d-flex justify-content-center gap-2">
                                                            <button
                                                                className="btn btn-primary btn-sm"
                                                                onClick={() => handleEditClick(offer.id)}
                                                            >
                                                                <i className="bi bi-pencil"></i>
                                                            </button>
                                                            <button
                                                                className="btn btn-danger btn-sm"
                                                                onClick={() => {
                                                                    setItemToDelete(offer.id);
                                                                    setShowDeleteModal(true);
                                                                }}
                                                            >
                                                                <i className="bi bi-trash"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="4" className="text-center align-middle">No offers available</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {showDeleteModal && (
                <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deletion</h5>
                                <button type="button" className="btn-close" onClick={() => setShowDeleteModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this offer?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => setShowDeleteModal(false)}>
                                    Cancel
                                </button>
                                <button type="button" className="btn btn-danger" onClick={() => handleDeleteClick(itemToDelete)}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <ToastContainer />
        </div>
    );
}

export default OfferList;
