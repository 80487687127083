import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';

import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';

function QuestionList() {
    const [questions, setQuestions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [questionToDelete, setQuestionToDelete] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [file, setFile] = useState(null);
    const navigate = useNavigate();
    const { userData } = useUser();

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/questions`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                    },
                });

                if (response.data.status === 'Success') {
                    setQuestions(response.data.data);
                } else {
                    toast.error('Failed to fetch questions.');
                }
            } catch (error) {
                console.error('Error fetching questions:', error);
                toast.error(`Error fetching questions: ${error.response?.data?.message || error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchQuestions();
    }, []);
    const handleFileUpload = async () => {
        if (!file) return;
    
        try {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const binaryStr = event.target.result;
                const workbook = XLSX.read(binaryStr, { type: 'binary' });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    
                const questions = jsonData
                    .map(row => ({
                        question_text: String(row[0] || '').trim(),
                        option1: String(row[1] || '').trim(),
                        option2: String(row[2] || '').trim(),
                        option3: String(row[3] || '').trim(),
                        option4: String(row[4] || '').trim(),
                        right_option: String(row[5] || '').trim(),
                    }))
                    .filter(q => q.question_text && q.option1 && q.option2 && q.option3 && q.option4 && q.right_option);
    
                if (questions.length === 0) {
                    toast.error('No valid questions found in the uploaded file.');
                    return;
                }
    
                try {
                    const response = await axios.post(`${BASE_URL}/questions/upload`, { questions }, {
                        headers: {
                            'Authorization': `Bearer ${userData?.token}`,
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        },
                    });
    
                    console.log("Sending JSON data:", response?.data);
    
                    if (response?.data?.message === 'Questions uploaded successfully') {
                        toast.success('File uploaded and data processed successfully!');
                        // fetchQuestions();
                    } else {
                        toast.error('Failed to upload the Excel file.');
                    }
                } catch (error) {
                    console.error("Upload error:", error?.response?.data || error.message);
                    toast.error(`Upload failed: ${error?.response?.data?.message || error.message}`);
                }
            };
            reader.readAsBinaryString(file);
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error(`Error: ${error.message}`);
        }
    };
 const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
 }    

    const handleAnswerClick = (id) => {
        navigate(`/dashboard/questions/${id}/answer`);
    };

    const handleEditClick = (id) => {
        navigate(`/dashboard/questions/${id}/edit`);
    };

    const handleDeleteClick = async (id) => {
        try {
            await axios.delete(`${BASE_URL}/questions/${id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`,
                },
            });
            setQuestions(questions.filter((question) => question.id !== id));
            toast.success('Question deleted successfully!');
        } catch (error) {
            console.error('Error deleting question:', error);
            toast.error(`Error deleting question: ${error.response?.data?.message || error.message}`);
        } finally {
            setShowDeleteModal(false);
        }
    };

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    const filteredQuestions = questions.filter(question =>
        question.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        question.options.some(option =>
            option.optionTitle.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                
                    <div className="dashboard-content mt-3">
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h1 className="fs-3 fw-bold text-dark shadow-sm p-3 mb-2 me-2 bg-body rounded">Questions List</h1>
                            <div className='d-flex justify-content-end align-items-center'>
                            <button
                                className="btn btn-primary shadow-sm"
                                onClick={() => navigate("/dashboard/questions/add")}
                            >
                                <i className="bi bi-plus-circle me-2"></i>Add New Question
                            </button>
                            <div className="input-group w-50 ms-3">
                                <input
                                    type="file"
                                    className="form-control"
                                    onChange={handleFileChange}
                                />
                                <button
                                    className="btn btn-success input-group-append"
                                    onClick={handleFileUpload}
                                >
                                    <i className="bi bi-upload me-2"></i>Upload
                                </button>
                            </div>
                            </div>
                        </div>

                        <input
                            type="text"
                            placeholder="Search questions..."
                            className="form-control mb-4"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />

                        {loading ? (
                            <div className="d-flex justify-content-center" style={{ height: '70vh' }}>
                                <Loader />
                            </div>
                        ) : (
                            <div className="table-responsive">
                                <table className="table table-hover table-striped table-bordered shadow-sm rounded" style={{fontSize: '14px'}}>
                                    <thead className="table-dark">
                                        <tr>
                                            <th>Question</th>
                                            <th>Option 1</th>
                                            <th>Option 2</th>
                                            <th>Option 3</th>
                                            <th>Option 4</th>
                                            <th>Correct Option</th>
                                            <th className="col-2 text-center">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredQuestions.length ? (
                                            filteredQuestions.map(question => (
                                                <tr key={question.id}>
                                                    <td className='text-truncate align-middle' style={{ maxWidth: '100px' }}>{question.title}</td>
                                                    {question.options.map((option, index) => (
                                                        <td key={option.id} className="text-truncate align-middle" style={{ maxWidth: '100px' }}>
                                                            {option.optionTitle}
                                                        </td>
                                                    ))}
                                                    <td className="text-truncate align-middle" style={{ maxWidth: '100px' }}>
                                                        {question.options.find(option => option.correctOption === 'Y')?.optionTitle || ''}
                                                    </td>
                                                    <td className="text-center align-middle d-flex justify-content-center">
                                                        <button
                                                            className="btn btn-primary btn-sm me-2"
                                                            onClick={() => handleEditClick(question.id)}
                                                        >
                                                            <i className="bi bi-pencil"></i>
                                                        </button>
                                                        <button
                                                            className="btn btn-danger btn-sm me-2"
                                                            onClick={() => {
                                                                setQuestionToDelete(question.id);
                                                                setShowDeleteModal(true);
                                                            }}
                                                        >
                                                            <i className="bi bi-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="7" className="text-center align-middle">No questions found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {showDeleteModal && (
                <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deletion</h5>
                                <button type="button" className="btn-close" onClick={() => setShowDeleteModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to delete this question?</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => setShowDeleteModal(false)}>
                                    Cancel
                                </button>
                                <button type="button" className="btn btn-danger" onClick={() => handleDeleteClick(questionToDelete)}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <ToastContainer /> 
        </div>
    );
}

export default QuestionList;
