import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../sidebar/Sidebar';
import { BASE_URL } from '../../Services';
import { useUser } from '../../Services/UserContext';

const AmbassadorDetailsPage = () => {
    const { id } = useParams();
    const [ambassador, setAmbassador] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const { userData } = useUser();

    useEffect(() => {
        const fetchAmbassador = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/users/${id}`, {
                    headers: {
                        Authorization: `Bearer ${userData?.token}`,
                    }
                });
                setAmbassador(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching ambassador:', error);
                setLoading(false);
            }
        };
        fetchAmbassador();
    }, [id, userData?.token]);

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);

        const toggleButton = document.querySelector('.sidebar-toggle');
        if (toggleButton) {
            const sidebarWidth = isOpen ? '250px' : '0px';
            const marginTop = isOpen ? '2px' : '3px'; 
            toggleButton.style.left = isOpen ? `calc(${sidebarWidth} - 10px)` : '1px';
            toggleButton.style.top = marginTop;
        }
    };

    if (loading) return <p>Loading...</p>;
    if (!ambassador) return <p>No ambassador found</p>;

    return (
        <div className="container-fluid">
           <div className="row">
    <Sidebar onToggleSidebar={handleSidebarToggle} />
    <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
        <div className="card p-3 mb-2 mt-1 border-0">
            <div className="card-header text-white" style={{ backgroundColor: '#007bff' }}>
                Referred By - {ambassador.name}
            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table table-hover table-striped table-bordered shadow-sm" style={{ fontSize: '14px' }}>
                        <thead className="table-dark">
                            <tr>
                                <th>SR No.</th>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Buying</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(ambassador.referrals_list) && ambassador.referrals_list.length > 0 ? (
                                ambassador.referrals_list.map((referral, index) => (
                                    <tr key={index}>
                                        <td className="align-middle">{index + 1}</td>
                                        <td className="align-middle">{referral.name}</td>
                                        <td className="align-middle">{referral.email}</td>
                                        <td className="align-middle">{referral.phone}</td>
                                        <td className="align-middle">{Number(referral.total_price).toFixed(2)}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="text-center">
                                        No referrals found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

        </div>
    );
};

export default AmbassadorDetailsPage;
