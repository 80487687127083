
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../../Services";

const ResendTicketMessage = ({ userId, ticketIds, currentProcessingId, setCurrentProcessingId }) => {
  const handleResendMessage = async () => {
    if (!userId || userId === "N/A") {
      toast.error("Invalid User ID.");
      return;
    }
    setCurrentProcessingId(userId);
    try {
      await axios.post(`${BASE_URL}/resend-message`, {
        user_id: userId,
        message: `Hi, your ticket number(s) for the Enaam.pk Lucky Draw is/are [ ${ticketIds} ]. Keep it safe! Good luck! Stay in touch with us for more updates.`,
        ticket_id: ticketIds,
      });
      toast.success("Message sent successfully.");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to send message. Please try again.");
    } finally {
      setCurrentProcessingId(null);
    }
  };

  return (
    <button className="btn btn-primary btn-sm" onClick={handleResendMessage}>
      {currentProcessingId === userId ? <i className="bi bi-hourglass-split"></i> : <i className="bi bi-envelope"></i>}
    </button>
  );
};

export default ResendTicketMessage;
