import React, { useState } from 'react';
import axios from 'axios';

import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useUser } from '../../Services/UserContext';
import { BASE_URL } from '../../Services';

import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../sidebar/Sidebar';
import Loader from '../../UserSide/Components/LoaderComponent'; 

function CreateReward() {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [image, setImage] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true); 
    const [loading, setLoading] = useState(false); 
    const { userData } = useUser();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('image', image);

        try {
            await axios.post(`${BASE_URL}/rewards`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userData?.token}`
                }
            });
            toast.success('Reward created successfully!');
            navigate('/dashboard/rewards');
        } catch (error) {
            if (error.response) {
                toast.error(`Error: ${error.response.data.message || 'Failed to create reward.'}`);
            } else if (error.request) {
                toast.error('Error: No response from the server.');
            } else {
                toast.error(`Error: ${error.message}`);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSidebarToggle = (isOpen) => {
        setIsSidebarOpen(isOpen);
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <Sidebar onToggleSidebar={handleSidebarToggle} />
                <div className={`col ${isSidebarOpen ? 'col-md-10' : 'col-md-12 mt-3'} ms-auto`}>
                       
                        <div className="container-fluid mt-4">
                        <div className="p-4 bg-light rounded shadow-sm">
                            {loading ? (
                                <div className="d-flex justify-content-center">
                                    <Loader /> 
                                </div>
                            ) : (
                                <div>
                                    <div className='d-flex justify-content-end px-3'>
                                        <Link to="/dashboard/rewards" className="btn btn-secondary shadow-sm">
                                                <i className="bi bi-arrow-left me-2"></i> Back to Rewards List
                                        </Link> 
                                    </div>
                                    <h1 className="mb-4 fs-4 fw-bold text-dark">Create Reward</h1>
                                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name</label>
                                            <input 
                                                type="text" 
                                                id="name" 
                                                className="form-control" 
                                                value={name} 
                                                onChange={(e) => setName(e.target.value)} 
                                                required 
                                            />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="description" className="form-label">Description</label>
                                            <textarea 
                                                id="description" 
                                                className="form-control" 
                                                value={description} 
                                                onChange={(e) => setDescription(e.target.value)} 
                                                required
                                            ></textarea>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="image" className="form-label">Image</label>
                                            <input 
                                                type="file" 
                                                id="image" 
                                                className="form-control" 
                                                onChange={(e) => setImage(e.target.files[0])} 
                                                required 
                                            />
                                        </div>
                                        <button type="submit" className="btn btn-primary shadow-sm">
                                            Create
                                        </button>
                                    </form>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer /> 
        </div>
    );
}

export default CreateReward;
