import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useUser } from "../../Services/UserContext.js";
import { BASE_URL } from "../../Services/index.js";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "../sidebar/Sidebar";
import Loader from "../../UserSide/Components/LoaderComponent";

import * as XLSX from "xlsx";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import Pagination from "@mui/material/Pagination";

const InvoicePage = () => {
  const [processed_invoices, setProcessedInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [amountFilter, setAmountFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [answerFilter, setAnswerFilter] = useState("");
  const { userData } = useUser();
  const [currentPage, setCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState("");
const [endDate, setEndDate] = useState("");

  const itemsPerPage = 10;

  useEffect(() => {
    const fetchProcessedInvoices = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/invoices`, {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        });
        setProcessedInvoices(response.data.processed_invoices);
        setFilteredInvoices(response.data.processed_invoices);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching processed invoices:", error);
        setLoading(false);
      }
    };
    fetchProcessedInvoices();
  }, [userData]);

  useEffect(() => {
    const filtered = processed_invoices.filter((invoice) => {
      const ticket_id = invoice.ticket_id
        ? invoice.ticket_id.toString().toLowerCase()
        : "";
      const user_name = invoice.user && invoice.user.name ? invoice.user.name.toLowerCase() : "";
      const user_email = invoice.user && invoice.user.email ? invoice.user.email.toLowerCase() : "";
      const user_phone = invoice.user?.phone || "";
      const invoiceDate = new Date(invoice.created_at);

      const answerMatch =
        answerFilter === "" || invoice.is_correct === answerFilter;

      const amountMatch =
        amountFilter === "" ||
        (amountFilter === "greater" && invoice.total_price > 0) ||
        (amountFilter === "less" && invoice.total_price == 0);
      
      const dateMatch =
      (!startDate || invoiceDate >= new Date(`${startDate}T00:00:00Z`)) &&
      (!endDate || invoiceDate <= new Date(`${endDate}T23:59:59Z`));

      return (
        (user_name.includes(searchTerm.toLowerCase()) ||
          user_email.includes(searchTerm.toLowerCase()) ||
          user_phone.includes(searchTerm) ||
          ticket_id.includes(searchTerm.toLowerCase())) &&
        answerMatch &&
        amountMatch &&
        dateMatch
      );
    });
    const sortedFiltered = filtered.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
    setCurrentPage(1);
    setFilteredInvoices(sortedFiltered);
  }, [searchTerm, answerFilter, amountFilter, startDate, endDate, processed_invoices]);

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

    const generatePDF = () => {
        const pdf = new jsPDF();
        const pageHeight = pdf.internal.pageSize.height;
        pdf.setFontSize(20); 
        pdf.text("Ticket Details", 10, 20);
        pdf.setFontSize(12);
    
        let position = 30; 
    
        filteredInvoices.forEach((invoice) => {
            const name = invoice.user ? invoice.user.name || "N/A" : 'N/A';
            const email = invoice.user ? invoice.user.email || "N/A" : 'N/A';
            const phone = invoice.user ? invoice.user.phone || "N/A" : 'N/A';
            const date = new Date(invoice.created_at).toLocaleDateString() || "N/A";
            const answer = invoice.is_correct || "N/A";
            const totalPrice = invoice.total_price ? Number(invoice.total_price).toFixed(2) : "N/A";
            
            if (invoice.ticket_id.length > 1) {
              invoice.ticket_id.forEach((ticketID) => {
                  pdf.setFont("helvetica", "bold");
                  pdf.text(`Ticket ID: ${ticketID}`, 10, position + 5); 
                  pdf.setFont("helvetica", "normal"); 
          
                  pdf.setFont("helvetica", "bold");
                  pdf.text("Name:", 10, position + 15);
                  pdf.setFont("helvetica", "normal");
                  pdf.text(name, 50, position + 15);
                  
                  pdf.setFont("helvetica", "bold");
                  pdf.text("Email:", 100, position + 15);
                  pdf.setFont("helvetica", "normal");
                  pdf.text(email, 140, position + 15);
          
                  pdf.setFont("helvetica", "bold");
                  pdf.text("Phone:", 10, position + 25);
                  pdf.setFont("helvetica", "normal");
                  pdf.text(phone, 50, position + 25); 
                  
                  pdf.setFont("helvetica", "bold");
                  pdf.text("Quantity:", 100, position + 25);
                  pdf.setFont("helvetica", "normal");
                  pdf.text("1", 140, position + 25); 

                  pdf.setFont("helvetica", "bold");
                  pdf.text("Price:", 100, position + 35); 
                  pdf.setFont("helvetica", "normal");
                  pdf.text(`${totalPrice}`, 140, position + 35);
          
                  pdf.setFont("helvetica", "bold");
                  pdf.text("Date:", 10, position + 35);
                  pdf.setFont("helvetica", "normal");
                  pdf.text(date, 50, position + 35); 
                  
                  // pdf.setFont("helvetica", "bold");
                  // pdf.text("Answer:", 100, position + 35);
                  // pdf.setFont("helvetica", "normal");
                  // pdf.text(answer, 140, position + 35);
                  
          
                  const dividerY = position + 50;
                  pdf.line(10, dividerY, 200, dividerY); 
          
                  position += 55; 
          
                  if (position > pageHeight - 30) {
                      pdf.addPage();
                      pdf.text("Invoice Details", 10, 20);
                      pdf.setFontSize(12); 
                      position = 30; 
                  }
              });
          } else {
              const ticketID = invoice.ticket_id[0];
              pdf.setFont("helvetica", "bold");
              pdf.text(`Ticket ID: ${ticketID}`, 10, position + 5); 
              pdf.setFont("helvetica", "normal");
          
              pdf.setFont("helvetica", "bold");
              pdf.text("Name:", 10, position + 15);
              pdf.setFont("helvetica", "normal");
              pdf.text(name, 50, position + 15);
              
              pdf.setFont("helvetica", "bold");
              pdf.text("Email:", 100, position + 15);
              pdf.setFont("helvetica", "normal");
              pdf.text(email, 140, position + 15); 
          
              pdf.setFont("helvetica", "bold");
              pdf.text("Phone:", 10, position + 25);
              pdf.setFont("helvetica", "normal");
              pdf.text(phone, 50, position + 25);
              
              pdf.setFont("helvetica", "bold");
              pdf.text("Quantity:", 100, position + 25);
              pdf.setFont("helvetica", "normal");
              pdf.text("1", 140, position + 25);

              pdf.setFont("helvetica", "bold");
              pdf.text("Price:", 100, position + 35); 
              pdf.setFont("helvetica", "normal");
              pdf.text(`${totalPrice}`, 140, position + 35);
          
              pdf.setFont("helvetica", "bold");
              pdf.text("Date:", 10, position + 35);
              pdf.setFont("helvetica", "normal");
              pdf.text(date, 50, position + 35);
          
              // pdf.setFont("helvetica", "bold");
              // pdf.text("Answer:", 100, position + 35);
              // pdf.setFont("helvetica", "normal");
              // pdf.text(answer, 140, position + 35); 
          
              const dividerY = position + 50; 
              pdf.line(10, dividerY, 200, dividerY); 
          
              position += 60; 
          
              if (position > pageHeight - 30) {
                  pdf.addPage();
                  pdf.text("Invoice Details", 10, 20); 
                  pdf.setFontSize(12);
                  position = 30; 
              }
          }
          
        });
    
        pdf.save('tickets.pdf');
    };

const downloadExcel = () => {
    if (filteredInvoices.length === 0) {
        alert("No invoices to download.");
        return;
    }

    const excelData = filteredInvoices.map((invoice, index) => ({
        "Sr": index + 1,
        "ID": Array.isArray(invoice.ticket_id) ? invoice.ticket_id.join(", ") : invoice.ticket_id || "N/A",
        "Name": invoice.user ? invoice.user.name : "N/A",
        "Email": invoice.user ? invoice.user.email : "N/A",
        "Phone": invoice.user ? invoice.user.phone : "N/A",
        "Quantity": invoice.total_quantity || 0,
        "Price": Number(invoice.total_price).toFixed(2),
        "Answer": invoice.is_correct || "N/A",
        "Date": new Date(invoice.created_at).toLocaleDateString(),
    }));
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const columnWidths = [
        { wch: 5 },   
        { wch: 25 },  
        { wch: 25 },  
        { wch: 35 },  
        { wch: 15 },  
        { wch: 10 }, 
        { wch: 12 },  
        { wch: 10 },
        { wch: 15 },  
    ];
    worksheet["!cols"] = columnWidths;
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");
    XLSX.writeFile(workbook, "Tickets_List.xlsx");
};

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedInvoices = filteredInvoices.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar onToggleSidebar={handleSidebarToggle} />
        <div
          className={`col ${
            isSidebarOpen ? "col-md-10" : "col-md-12 mt-3"
          } ms-auto`}
        >
          <div className="dashboard-content p-4">
            <div className="total-users-box mb-4 p-3 bg-light rounded shadow-sm d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
              <div className="d-flex align-items-center mb-3 mb-md-0">
                <h4 className="mb-0">Tickets: {filteredInvoices.length}</h4>
              </div>
              <div className="d-flex gap-2 flex-wrap justify-content-start justify-content-md-end">
                <button onClick={generatePDF} className="btn btn-primary"><bi className="bi bi-download"></bi>  PDF </button>
                <button className="btn btn-success" onClick={downloadExcel}><bi className="bi bi-download"></bi>  Excel</button> 
              </div>
            </div>
            <div className="d-flex justify-content-start mb-3">
              <input
                type="text"
                placeholder="Search invoices..."
                className="form-control "
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <select
                className="form-control ms-2 "
                value={answerFilter}
                onChange={(e) => setAnswerFilter(e.target.value)}
              >
                <option value="">All</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
                    <select
                        className="form-control ms-2"
                        value={amountFilter}
                        onChange={(e) => setAmountFilter(e.target.value)}
                    >
                        <option value="">All Amounts</option>
                        <option value="greater">Paid</option>
                        <option value="less">Unpaid</option>
                    </select>
                    <input
                      type="date"
                      className="form-control ms-2"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    <input
                      type="date"
                      className="form-control ms-2"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                
            </div>
            {loading ? (
              <div className="d-flex justify-content-center" style={{ height: "60vh" }}>
                <Loader />
              </div>
            ) : (
              <>
                <div className="table-responsive mt-2">
                  <table
                    id="invoice-table"
                    className="table table-striped table-bordered shadow-sm rounded"
                    style={{ fontSize: "14px" }}
                  >
                    <thead className="table-dark">
                      <tr>
                        <th>Sr</th>
                        <th className="col-1 text-center">ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th className="col-md-1 text-center">Quantity</th>
                        <th className="col-md-1 text-center">Price</th>
                        <th className="col-md-1 text-center">Answer</th>
                        <th className="col-md-1 text-center">Date</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedInvoices.length === 0 ? (
                        <tr>
                          <td colSpan="10" className="text-center">
                            No invoices found
                          </td>
                        </tr>
                      ) : (
                        paginatedInvoices.map((invoice, index) => (
                          <tr key={invoice.id}>
                            <td className="align-middle">
                              {startIndex + index + 1}
                            </td>
                            <td className="align-middle">
                              {Array.isArray(invoice.ticket_id)
                                ? invoice.ticket_id.join(",  ")
                                : invoice.ticket_id || "N/A"}
                            </td>
                            <td className="align-middle">
                              {invoice.user ? invoice.user.name : "N/A"}
                            </td>
                            <td className="align-middle">
                              {invoice.user ? invoice.user.email : "N/A"}
                            </td>
                            <td className="align-middle">
                              {invoice.user ? invoice.user.phone : "N/A"}
                            </td>
                            <td className="text-center align-middle">
                              {invoice.total_quantity}
                            </td>
                            <td className="text-center align-middle">
                              {Number(invoice.total_price).toFixed(2)}
                            </td>
                            <td className="text-center align-middle">
                              {invoice.is_correct}
                            </td>
                            <td className="align-middle">
                              {new Date(invoice.created_at).toLocaleDateString()}
                            </td>
                            <td className="text-center align-middle">
                              <div className="d-flex gap-2 justify-content-center">
                                <Link
                                  to={`/dashboard/invoices/${invoice.id}`}
                                  className="btn btn-info btn-sm"
                                >
                                  <i className="bi bi-eye"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <Pagination
                    count={Math.ceil(filteredInvoices.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    variant="outlined"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default InvoicePage;
