import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../Services';
import { Helmet } from 'react-helmet';

import Header from '../../Components/HeaderComponent';
import Footer from '../../Components/FooterCompnent';
import Loader from '../../Components/LoaderComponent';
import htmlTruncate from 'html-truncate';

import "../../css/Styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/blogs`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (response.data.status === 200 && Array.isArray(response.data.blogs)) {
          setBlogs(response.data.blogs);
        } else {
          console.error('Unexpected response format:', response.data);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div className='App'>
      <Helmet>
        <title>Enaam.pk Blogs - Insights, Tips, and Updates</title>
          <meta name="description" content="Explore Enaam.pk blogs for insights on competitions, tips for winning prizes, and updates about our platform. Stay informed and inspired!" />
          <link rel="canonical" href="https://enaam.pk/blogs" />
      </Helmet>
      <Header />
      <section  className="home-header inner-page p-3">
        <div style={{marginTop:"150px"}} className="container-fluid col-lg-10 ">
          <h3 className="title-style mb-4 text-center " style={{ fontWeight: "600",fontSize: "30px" }}>BLOGS</h3>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                {loading ? (
                  <Loader />
                ) : (
                  blogs.length > 0 ? (
                    blogs.map((blog) => (
                      <div className="col-md-4 mb-4" key={blog.id}>
                        <div className="card h-100 shadow-sm" style={{ borderRadius: "15px" }}>
                          <div className="card-img-top">
                            <img src={blog.blog_image} alt={blog.heading} style={{ height: "250px", width: "100%", objectFit: "fill", borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }} />
                          </div>
                          <div className="card-body d-flex flex-column text-start">

                            <h5 className="card-title">{blog.heading}</h5>
                            <p className="card-text" dangerouslySetInnerHTML={{ __html: htmlTruncate(blog.description, 40) }} />
                            <button className="btn btn-primary mt-auto" onClick={() => navigate(`/blogs/${blog.slug}`)}>Read More</button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-12 text-center">No blogs available</div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Blogs;
